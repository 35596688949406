import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import { useAuthState } from "react-firebase-hooks/auth";
import "./style/dashboard.css";
import "./style/listbox.css";
import "./style/app.css";
import "./style/auth.css";
import "./style/newTest.css";
import "./style/nav.css";
import "./style/test.css";
import { auth } from "./config/firebase";
import Spinner from "./components/Spinner";
import Admin from "./components/Admin";
import "./style/testInstructions.css";
import Company from "./components/UploadIds";

function App() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <div style={{ padding: 20 }}>
        <Spinner color="#000" />
      </div>
    );
  }
  return (
    <div className="app">
      {user ? (
        <div>
          <Routes>
            <Route path="*" element={<Admin />}>
              <Route path="uploadIds" element={<Company />} />
            </Route>
          </Routes>
        </div>
      ) : (
        <div>
          <div className="content">
            <Routes>
              <Route exact path="/" index element={<Login />} />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
}
const RouterWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
export default RouterWrapper;
