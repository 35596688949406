import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import api_url from "../constants";

const Banner = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [filePath, setFilePath] = useState("");
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getBanners();
  }, []);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getBanners = async () => {
    const response = await axios.get(`${api_url}/api/getBanners`);
    setBanners(response.data);
  };

  const onFileUpload = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setMessage("Please select a file to upload");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const res = await axios.post(`${api_url}/api/uploadBanner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(res.data.message);
      setFilePath(res.data.file);
      toast.success("Image uploaded successfully!");
      getBanners(); // Refresh the list of banners
    } catch (error) {
      console.error("There was an error uploading the file!", error);
      setMessage("There was an error uploading the file!");
      toast.error("There was an error uploading the file!");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBanner = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${api_url}/api/deleteBanner/${id}`);
      toast.success("Banner deleted successfully!");
      getBanners(); // Refresh the list of banners
    } catch (error) {
      console.error("There was an error deleting the banner!", error);
      toast.error("There was an error deleting the banner!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-content">
      <ToastContainer />
      <h2>Upload Image</h2>
      <form onSubmit={onFileUpload} className="upload-form">
        <input type="file" onChange={onFileChange} />
        <button type="submit" disabled={isLoading}>
          Upload
        </button>
      </form>
      {isLoading && (
        <div className="loader-container">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
      {message && <p className="message">{message}</p>}

      <div className="banners">
        {banners.map((banner) => (
          <div className="banner-cont" key={banner._id}>
            <div
              className="del-banner"
              onClick={() => deleteBanner(banner._id)}
            >
              Delete
            </div>
            <img
              src={`https://api2.fly247.in/${banner.filePath}`}
              alt="Uploaded File"
              className="banner-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
