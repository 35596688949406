import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import api_url from "../constants";

const Company = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [filePath, setFilePath] = useState("");
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCompanies();
  }, []);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getCompanies = async () => {
    const response = await axios.get(`${api_url}/api/getCompanies`);
    setCompanies(response.data);
  };
  const deleteCompany = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${api_url}/api/deleteCompany/${id}`);
      toast.success("Banner deleted successfully!");
      getCompanies(); // Refresh the list of banners
    } catch (error) {
      console.error("There was an error deleting the banner!", error);
      toast.error("There was an error deleting the banner!");
    } finally {
      setIsLoading(false);
    }
  };
  const onFileUpload = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setMessage("Please select a file to upload");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("name", name);
    formData.append("whatsapp", whatsapp);

    try {
      const res = await axios.post(`${api_url}/api/uploadCompany`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(res.data.message);
      setFilePath(res.data.file);
      toast.success("Image uploaded successfully!");
      getCompanies();
    } catch (error) {
      console.error("There was an error uploading the file!", error);
      setMessage("There was an error uploading the file!");
      toast.error("There was an error uploading the file!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-content">
      <ToastContainer />
      <h2>Id Companies</h2>
      <form onSubmit={onFileUpload} className="upload-form">
        <input
          type="text"
          placeholder="Id Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <input
          type="text"
          placeholder="Whatsapp Number"
          onChange={(e) => setWhatsapp(e.target.value)}
          value={whatsapp}
        />
        <input type="file" onChange={onFileChange} />
        <button type="submit" disabled={isLoading}>
          Upload
        </button>
      </form>
      {isLoading && (
        <div className="loader-container">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
      {message && <p className="message">{message}</p>}

      <div className="sites">
        {companies.map((company) => (
          <div className="site" key={company._id}>
            <div
              className="del-banner"
              onClick={() => deleteCompany(company._id)}
            >
              Delete
            </div>
            <img
              src={`https://api2.fly247.in/${company.iconPath}`}
              alt={company.name}
            />
            <span>{company.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Company;
