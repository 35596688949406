import React, { useEffect, useState } from "react";
import "../style/test.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../config/firebase";
import Spinner from "./Spinner";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import NavItem from "./NavItem";
import ClassIcon from "@mui/icons-material/Class";
import { Route, Routes, useNavigate } from "react-router-dom";
import Menu from "@mui/icons-material/Menu";
import UploadBanners from "./UploadBanners";
import UploadIds from "./UploadIds";

function Admin() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [activeSidebar, setActiveSidebar] = useState(false);
  const close = () => {
    setActiveSidebar(false);
  };
  if (loading) {
    return (
      <div style={{ flex: 1, padding: 20 }}>
        <Spinner color="#000" />
      </div>
    );
  }

  return (
    <div className="bg-w admin-f">
      <header className="test-header">
        <div
          className="right-menu"
          onClick={() => {
            setActiveSidebar(!activeSidebar);
          }}
        >
          <Menu style={{ fill: "#000", fontSize: 30, marginRight: 10 }} />
          <h1 className="Big">Admin</h1>
        </div>
        <button
          className="dashboard__btn"
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          Logout
        </button>
      </header>
      <div className="test-flex">
        <div
          className={`left-c pt-25 admin-nav blue-theme ${
            activeSidebar ? "open-c" : null
          }`}
        >
          <NavItem
            title={"Upload Banner"}
            path="/"
            Icon={GridViewRoundedIcon}
            onClick={close}
          />
          <NavItem
            title={"Upload Ids"}
            path="/uploadIds"
            Icon={ClassIcon}
            onClick={close}
          />
        </div>
        <div className="right-c admin-c">
          <Routes>
            <Route path="/" element={<UploadBanners />} />
            <Route path="uploadIds" element={<UploadIds />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Admin;
